
@import './../../../../scss/theme-bootstrap';

.product-brief__container {
  text-align: center;
  position: relative;
  padding: 0 5px 0;
  @include breakpoint($medium-up) {
    padding: 0 10px 0;
  }
  .spp-cross-sells--by-sku & {
    @include swap_direction(padding, 0 10px 0 5px);
  }
  a {
    border: 0;
  }
  .product-flag__flag {
    position: absolute;
    top: 20px;
    #{$ldirection}: 124px;
    font-size: 14px;
    line-height: 9px;
    width: 54px;
    text-align: center;
    @include breakpoint($medium-up) {
      top: 45px;
      #{$ldirection}: 188px;
    }
  }
  .product-brief__product-header-blurb {
    display: none;
    @include h1;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-bottom: 25px;
    }
    .mpp__show-column-header & {
      display: block;
    }
  }
  .product__image-link {
    display: inline-block;
    border: none;
    width: 100%; // img placeholder

    .product__image {
      max-width: 256px;
      max-height: 315px;
      display: block;
      width: 100%;
      margin: 0 auto;
      min-height: 150px; // img placeholder

      .product-grid--medium-cols-3 & {
        @include breakpoint($medium-up) {
          max-width: 387px;
          max-height: 450px;
        }
      }
    }
  }
  .product-brief__name {
    @include h4;
    font-size: 11px;
    margin: 18px 0 0;
    .product-grid--small-cols-1 & {
      font-size: 12px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
    @include breakpoint($medium-up) {
      font-size: 14px;
      margin: 28px 0 0;
    }
    .spp-cross-sells--by-collection & {
      font-size: 10px;
      @include breakpoint($medium-up) {
        font-size: 12px;
      }
    }
  }
  .product-brief__sub-name {
    @include font-face--helvetica-roman;
    margin: 12px 0 0;
    font-size: 11px;
    line-height: 12px;
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 15px;
    }
  }
  .product-brief__subline {
    display: block;
  }
  .product-brief__sku-size,
  .product-brief__price {
    margin: 12px 0 0;
    font-size: 11px;
    line-height: 12px;
    .product-grid--small-cols-1 & {
      font-size: 12px;
      @include breakpoint($medium-up) {
        font-size: 14px;
        line-height: 15px;
      }
    }
    @include breakpoint($medium-up) {
      margin: 15px 0 0;
      font-size: 14px;
      line-height: 15px;
    }
  }
  .product-brief__sku-size {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .product-brief__price {
    @include font--helvetica-neue-medium;
  }
  .product-brief__quickshop-launch-wrapper {
    margin: 0;
    @include breakpoint($medium-up) {
      margin: 20px 0 0;
    }
    .button {
      background-color: inherit;
      border: 1px solid $color-light-stone-gray;
      border-radius: 4px;
      color: $color-black;
      font-size: 8px;
      line-height: 8px;
      padding: 7px 8px 6px;
      @include breakpoint($medium-up) {
        color: $color-stone-gray;
        font-size: 10px;
        line-height: 10px;
        padding: 8px 10px 6px;
      }
    }
  }
  .product-brief__quickshop-launch {
    &.qs-active {
    }
  }
  .product__inventory-status {
    padding: 0;
    margin: 0;
    color: $color-gray;
  }
  .product__inventory-status-item {
    margin: 12px 0 0;
  }
  .spp-cross-sells--by-collection & {
    .product-brief__cta {
      background-color: $color-white;
      color: $color-black;
    }
  }
}
